import { Icon } from '@elkaso-app/web-design';
import { ability } from 'configs/ability';
import { useFeatureFlags } from 'feature-flags';
import { AggSheetIcon } from 'layout/components/sidebar/icons/agg-sheet-icon';
import { CatalogIcon } from 'layout/components/sidebar/icons/catalog-icon';
import { CategoriesIcon } from 'layout/components/sidebar/icons/categories-icon';
import { CountiesIcon } from 'layout/components/sidebar/icons/counties-icon';
import { ElkasoUsersIcon } from 'layout/components/sidebar/icons/elkaso-users-icon';
import { OrdersIcons } from 'layout/components/sidebar/icons/orders-icons';
import { OtpIcon } from 'layout/components/sidebar/icons/otp-icon';
import { PosIntegrationsIcons } from 'layout/components/sidebar/icons/pos-integrations-icons';
import { PreferencesIcon } from 'layout/components/sidebar/icons/preferences-icon';
import { ProductIcon } from 'layout/components/sidebar/icons/product-icon';
import { PromotionsIcon } from 'layout/components/sidebar/icons/promotions-icon';
import { RestaurantTransfersIcons } from 'layout/components/sidebar/icons/restaurant-transfers-icons';
import { RestaurantsIcons } from 'layout/components/sidebar/icons/restaurants-icons';
import { StatisticsIcon } from 'layout/components/sidebar/icons/statistics-icon';
import { SuppliersIcon } from 'layout/components/sidebar/icons/suppliers-icon';
import { TicketIcon } from 'layout/components/sidebar/icons/ticket-icon';
import { UserManagementIcon } from 'layout/components/sidebar/icons/user-management-icon';
import { WhatsAppIcon } from 'layout/components/sidebar/icons/whatsApp-icon';
import { BenchmarkingIcon } from '../icons/benchmarking-icon';

export type TSidebarItem = {
  name: string;
  path: string;
  icon: typeof Icon;
  children?: {
    name: string;
    path: string;
    disable?: boolean;
  }[];
  hasSocket?: boolean;
  disable?: boolean;
};

export const useSidebarItems = () => {
  const { is_cms_v1_11_0_enabled } = useFeatureFlags();
  const isSuperAdmin = ability.can('manage', 'AggregatedSheet');
  const isDeveloper = ability.can('manage', 'all');

  const sidebarItems: TSidebarItem[] = [
    {
      name: 'sidebar.statistics',
      path: '/app/statistics',
      icon: StatisticsIcon,
    },
    {
      name: 'sidebar.benchmarking',
      path: '/app/benchmarking',
      icon: BenchmarkingIcon,
    },
    {
      name: 'sidebar.restaurants',
      path: '/app/restaurants',
      icon: RestaurantsIcons,
      children: [
        {
          name: 'Restaurants',
          path: '/app/restaurants/list',
        },
        {
          name: 'Documents',
          path: '/app/restaurants/documents',
        },
      ],
    },
    {
      name: 'sidebar.suppliers',
      path: '/app/suppliers',
      icon: SuppliersIcon,
    },
    {
      name: 'sidebar.products',
      path: '/app/products',
      icon: ProductIcon,
      disable: !isDeveloper,
    },
    {
      name: 'sidebar.aggSheetRelations',
      path: '/app/agg-sheet-relations',
      icon: AggSheetIcon,
      disable: !isSuperAdmin,
    },
    {
      name: 'sidebar.catalogManagement',
      path: '/app/catalog',
      icon: CatalogIcon,
      children: [
        {
          name: 'Food Universe',
          path: '/app/catalog/food-universe',
          disable: true,
        },
        {
          name: 'Product Categories',
          path: '/app/catalog/product-categories',
        },
      ],
    },
    {
      name: 'sidebar.ticketManagement',
      path: '/app/ticket',
      icon: TicketIcon,
    },
    {
      name: 'sidebar.promotions',
      path: '/app/promotions',
      icon: PromotionsIcon,
      children: [
        {
          name: 'Today’s Deals',
          path: '/app/promotions/list',
        },
        {
          name: 'Campaign',
          path: '/app/promotions/campaigns',
        },
      ],
    },
    {
      name: 'sidebar.elkasoUsers',
      path: '/app/elkasoUsers',
      icon: ElkasoUsersIcon,
    },
    {
      name: 'sidebar.orders',
      path: '/app/orders',
      icon: OrdersIcons,
    },
    {
      name: 'sidebar.restaurantTransfers',
      path: '/app/restaurant-transfers',
      icon: RestaurantTransfersIcons,
    },
    {
      name: 'sidebar.posIntegrations',
      path: '/app/pos-integrations',
      icon: PosIntegrationsIcons,
      children: [
        {
          name: 'POS Integrations',
          path: '/app/pos-integrations/list',
        },
        {
          name: 'POS issues',
          path: '/app/pos-integrations/issues',
        },
      ],
    },
    {
      name: 'sidebar.categories',
      path: '/app/categories',
      icon: CategoriesIcon,
    },
    {
      name: 'sidebar.userManagement',
      path: '/app/user-management',
      icon: UserManagementIcon,
    },
    {
      name: 'sidebar.permissions',
      path: '/app/preferences',
      icon: PreferencesIcon,
    },
    {
      name: 'sidebar.otp',
      path: '/app/sms-otp',
      icon: OtpIcon,
    },
    {
      name: 'sidebar.whatsNumbers',
      path: '/app/whats-numbers',
      icon: WhatsAppIcon,
      disable: !!is_cms_v1_11_0_enabled,
    },
    {
      name: 'sidebar.countries',
      path: '/app/countries',
      icon: CountiesIcon,
    },
  ];

  return sidebarItems;
};
